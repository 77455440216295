import { Component } from '@angular/core';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss'],
})
export class ThemeToggleComponent {
  constructor(private themeService: ThemeService) { }

  toggleTheme() {
    if (this.themeService.appThemeString == this.themeService.LIGHT_THEME) {
      this.themeService.setAppTheme(this.themeService.DARK_THEME);
    }
    else {
      this.themeService.setAppTheme(this.themeService.LIGHT_THEME);
    }
  }

  toggleIsInDarkMode(){
    return this.themeService.appThemeString == this.themeService.DARK_THEME;
  }
}