import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, InteractionStatus, RedirectRequest, PopupRequest, AuthenticationResult, EventType } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { UserFacade } from './core/facades/user.facade';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
//import { NgEventBus } from 'ng-event-bus';
import { EllyBreakpoints } from './shared/interfaces/elly-breakpoints';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'EllyAI';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private translateService: TranslateService,
    private userFacade: UserFacade,
    private breakpointObserver: BreakpointObserver,
    //private eventBus: NgEventBus
  ) {

  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

      this.breakpoint$.subscribe(() =>
        this.breakpointChanged()
      );
  }

  private breakpointChanged() {
  //   if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
  //     this.eventBus.cast('app:breakpointChanged', EllyBreakpoints.Handset);
  //   }
  //   else {
  //     this.eventBus.cast('app:breakpointChanged', EllyBreakpoints.Desktop);
  //   }

  //   return;

  //   if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
  //     this.eventBus.cast('app:breakpointChanged', Breakpoints.Large);
  //   } else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
  //     this.eventBus.cast('app:breakpointChanged', Breakpoints.Medium);
  //   } else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
  //     this.eventBus.cast('app:breakpointChanged', Breakpoints.Small);
  //   } else if(this.breakpointObserver.isMatched(Breakpoints.Handset)) {
  //     this.eventBus.cast('app:breakpointChanged', Breakpoints.Handset);
  //   } else if(this.breakpointObserver.isMatched('(min-width: 500px)')) {
  //     this.eventBus.cast('app:breakpointChanged', '(min-width: 500px)');
  //   }
  }

  setLoginDisplay() {
    // if(this.authService.instance.getAllAccounts().length > 0) {
    //   console.log("Account", this.authService.instance.getAllAccounts());
    // } else {
    //   this.loginRedirect();
    // }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    if (this.authService.instance.getActiveAccount()) {
      this.userFacade.getCurrentUserProfile();
    }
  }

  loginRedirect() {
    // if (this.msalGuardConfig.authRequest){
    //   this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    // } else {
    //   this.authService.loginRedirect();
    // }
  }

  // loginPopup() {
  //   if (this.msalGuardConfig.authRequest){
  //     this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
  //       .subscribe((response: AuthenticationResult) => {
  //         this.authService.instance.setActiveAccount(response.account);
  //       });
  //     } else {
  //       this.authService.loginPopup()
  //         .subscribe((response: AuthenticationResult) => {
  //           this.authService.instance.setActiveAccount(response.account);
  //     });
  //   }
  // }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/logout"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}