import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, finalize, tap, throwError } from 'rxjs';
import { currentAppSettings } from 'src/app/appsettings.service';
import { FeedbackRequest } from 'src/app/shared/interfaces/feedback.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { FeedbackOption } from 'src/app/shared/interfaces/feedbackOption.interface';
import { FeedbackModel } from 'src/app/shared/interfaces/responseFeedback.interface';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private httpClient: HttpClient) {}

  private feedbackOptions = new BehaviorSubject<FeedbackOption[]>([]);
  private feedbackOptionsIsLoading = new BehaviorSubject<boolean>(false);

  private headers = new HttpHeaders(
    {
      //'Content-Type': 'application/json',
      //'Authorization': `Bearer ${this.apiKey}`
      'Access-Control-Allow-Methods': 'GET,POST'
      , 'Access-Control-Allow-Origin': '*'//'https://api.openai.com'
      , 'Access-Control-Allow-Headers': '*'//'Origin, X-Requested-With, Content-Type, Accept'
    });

  public submitFeedback(feedbackRequest: FeedbackRequest) {

    return this.httpClient.post<FeedbackRequest>(`${currentAppSettings.apiUrl}` + 'Feedback/Add', feedbackRequest, { headers: this.headers }).subscribe(
      (response: FeedbackRequest) => {


      }
    );

  }

  public submitResponseFeedback(feedbackModel: FeedbackModel) {
    return this.httpClient.post<FeedbackModel>(`${currentAppSettings.apiUrl}` + 'Feedback/Add', feedbackModel, { headers: this.headers }).subscribe(
      (response: FeedbackModel) => {}
    );
  }

  getFeedbackOptions(): Observable<FeedbackOption[]> {
    this.feedbackOptionsIsLoading.next(true);

    return this.httpClient
      .get<FeedbackOption[]>(
        `${currentAppSettings.apiUrl}Feedback/FeedbackOptions`,
        { headers: this.headers }
      )
      .pipe(
        tap((response: FeedbackOption[]) => {
          this.feedbackOptions.next(response);
        }),
        catchError((error) => {
          console.error('Error fetching feedback options', error);
          this.feedbackOptions.next([]);  // Emit an empty array in case of error
          return throwError(() => error); // Return the error for further handling
        }),
        finalize(() => {
          this.feedbackOptionsIsLoading.next(false);
        })
      );
  }

  // public getCurrentUserProfile() {

  //   this.currentUser.next(null);

  //   return this.httpClient.get<User>(`${currentAppSettings.apiUrl}` + 'Users/GetCurrentUser').subscribe(//, { headers: this.headers }).subscribe(
  //     (user: User) => {

  //       this.currentUser.next(user);

  //     }
  //   );
  // }

}
