import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { count } from 'console';
import { BehaviorSubject, Observable, delay, retry } from 'rxjs';
import { currentAppSettings } from 'src/app/appsettings.service';
import { User } from 'src/app/shared/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public currentUser: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);
  public currentUser$: Observable<User | null> =
    this.currentUser.asObservable();

  protected currentUserPic: BehaviorSubject<Blob | null> =
    new BehaviorSubject<Blob | null>(null);
  public currentUserPic$: Observable<Blob | null> =
    this.currentUserPic.asObservable();

  private headers = new HttpHeaders({
    //'Content-Type': 'application/json',
    //'Authorization': `Bearer ${this.apiKey}`
    'Access-Control-Allow-Methods': 'GET,POST',
    'Access-Control-Allow-Origin': '*', //'https://api.openai.com'
    'Access-Control-Allow-Headers': '*', //'Origin, X-Requested-With, Content-Type, Accept'
  });

  constructor(private httpClient: HttpClient) {}

  public getCurrentUserProfile() {
    this.currentUser.next(null);

    return this.httpClient
      .get<User>(`${currentAppSettings.apiUrl}` + 'Users/GetCurrentUser')
      .pipe(retry({ count: 3, delay: 1000 }))
      .subscribe(
        (user: User) => {
          this.currentUser.next(user);
        }
      );
  }

  public getCurrentUserPic(userId: string) {
    this.currentUserPic.next(null);

    return this.httpClient
      .get(`${currentAppSettings.apiUrl}` + `Users/photo/${userId}`, {
        headers: this.headers,
        responseType: 'blob',
      })
      .pipe(retry({ count: 3, delay: 1000 }))
      .subscribe((userPic: Blob) => {
        this.currentUserPic.next(userPic);
      });
  }
}
