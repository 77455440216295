import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { UserFacade } from '../../facades/user.facade';
import { User } from 'src/app/shared/interfaces/user.interface';
import { BehaviorSubject, tap } from 'rxjs';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-user-profile-image',
  templateUrl: './user-profile-image.component.html',
  styleUrls: ['./user-profile-image.component.css']
})
export class UserProfileImageComponent implements OnInit {

  destroyedRef = inject(DestroyRef);

  _user: User | null = null;
  profilePic$ = this.userFacade.currentUserPic$;
  hasPhoto$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initials$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  photoUrl$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  @Input() 
  public set user(v : User | null) {    
    this._user = v;
    // this.hasPhoto$.next(true);
    // this.userFacade.getCurrentUserPic('cciulla@eliassen.com');
    
    if (this._user?.hasPhoto) { 
      this.hasPhoto$.next(true);
      this.userFacade.getCurrentUserPic(this._user.id);
    }
    else {
      this.hasPhoto$.next(false);
      if (this?._user) {
        //this.initials$.next("JS");
        this.initials$.next(this._user?.givenName[0] + this._user?.surname[0]);
      }
    }
  }
  

  constructor(private userFacade: UserFacade) { }

  ngOnInit() {    
    this.profilePic$
    .pipe(takeUntilDestroyed(this.destroyedRef))
    .subscribe((blob) => {      
      if (blob) {
        this.photoUrl$.next(URL.createObjectURL(blob));
      }
    });
  }

}
