import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChatFacade } from '../../../chat/facades/chat.facade';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { MatDialog } from '@angular/material/MatDialog'; 

import { FeedbackRequest } from 'src/app/shared/interfaces/feedback.interface';
import { FeedbackFacade } from '../../facades/feedback.facade';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss'
})
export class FeedbackComponent implements OnInit, AfterViewInit {

  @ViewChild('txtDescription', { read: ElementRef }) txtDescription!: ElementRef;

  feedbackForm!: FormGroup;

  feedbackRequest?: FeedbackRequest;

  constructor(private feedbackFacade: FeedbackFacade, private dialogRef: MatDialogRef<FeedbackComponent>, public formBuilder: FormBuilder) {

    this.createForm();

  }

  ngAfterViewInit() {

    this.txtDescription?.nativeElement.focus();

  }

  ngOnInit(): void {



    //this.clearForm();



  }

  createForm() {

    this.feedbackForm = this.formBuilder.group(
      {
        comments: new FormControl('', [Validators.required]),
      }
    );

  }


  submitFeedback() {

    this.feedbackFacade.submitFeedback(
      {
        comments: this.feedbackForm.get('comments')?.value
      }
    );

    this.dialogRef.close();

  }


  clearForm() {

    this.feedbackForm.reset();
    this.dialogRef.close();

  }

}
