<!-- <mat-toolbar class="toolbar-container">
  <div class="logo toolbar-container-img">

    <a (click)="onChatDashboardNavigate()">
      <img class="logo-elly m-r-1" src="assets/images/ellyBot-banner.png" />
    </a>

    <img class="logo-img" src="assets/images/eg-logo.png" />

  </div>

  <div class="env">
    {{ envName }}
  </div>
  <div>
    <ng-container *ngIf="(currentUser$ | async) as currentUser">

      <button [matMenuTriggerFor]="profilemenu" class="avatar avatar__button" mat-fab
        [color]="!currentUser.hasPhoto ? 'primary': ''" [ngClass]="{'avatar__button-align': currentUser.hasPhoto}">
        <app-user-profile-image [user]="currentUser"></app-user-profile-image>
      </button>


      <mat-menu #profilemenu class="profile-row">
        <div class="profile-name">
          {{ currentUser.displayName }}
        </div>

        <div class="profile-row-button">
          <button mat-raised-button (click)="onClick()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sign out</span>
          </button>
        </div>

        <div class="profile-row-build-details">
          UI Build Date: {{uiBuildDetails}}
        </div>
        <div class="profile-row-build-details">
          API Build Date: {{(apiSettings$ | async)}}
        </div>
      </mat-menu>
    </ng-container>
  </div>
</mat-toolbar> -->

<side-menu-toggle class="side-menu-toggle" (click)="toggleSideMenu()" [(isOpen)]="sideMenuOpened" [style.left.rem]="sideMenuTogglePadding"></side-menu-toggle>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav class="sidenav-menu" #sideNav mode="side" [(opened)]="sideMenuOpened">
    <span class="elly-top disp-f ai-c m-l-1/4"
    [ngClass]="{'jc-c m-b-1/2': isExternal}"
    >
      <a (click)="onChatDashboardNavigate()">

        <span *ngIf="!isExternal">
          <img class="logo-elly m-r-1" [src]='ellyLogo()' />
        </span>
        <span>
          <span class="logo-title" *ngIf="!isExternal">Elly</span>
          <span class="logo-subtitle" *ngIf="!isExternal">AI Assistant</span>
          <span class="logo-nobanner-title" *ngIf="isExternal">Client AI Demo</span>
        </span>
      </a>
    </span>

    <div class="sidenav-menu-items" *ngFor="let menu of (menuItems$ | async)" [class.active-link]="rla.isActive">
      <div class="sidenav-menu-sub-items disp-f ai-c" [class.active-link]="rla.isActive">
        <a class="disp-f ai-c" id="{{menu.name}}" routerLink="/{{menu.navigateTo}}" routerLinkActive="active-link"
          #rla="routerLinkActive" (click)="onMenuClick(menu)" title="{{menu.tooltip}}">

          <mat-icon svgIcon="{{ menu.iconName }}" *ngIf="menu.menuType === MenuItemType.CUSTOM" class="left-icon"></mat-icon>
          <mat-icon *ngIf="menu.menuType === MenuItemType.DEFAULT">{{ menu.iconName }}</mat-icon>

          <span class="m-l-1 f-w-500">
            {{ menu.tooltip }}
          </span>
        </a>

      </div>
    </div>

    <div class="footer">
      <app-theme-toggle></app-theme-toggle>

      <div class="m-b-1-1/2">
        <img class="logo-img" [src]="eliassenLogo()" />
      </div>

      <div>
        <ng-container *ngIf="(currentUser$ | async) as currentUser">

          <button [matMenuTriggerFor]="profilemenu" class="avatar avatar__button" mat-fab
            [color]="!currentUser.hasPhoto ? 'primary': ''" [ngClass]="{'avatar__button-align': currentUser.hasPhoto}">
            <app-user-profile-image [user]="currentUser"></app-user-profile-image>
          </button>
          <span class="m-l-1/2 f-w-600">
            {{ currentUser.displayName }}
          </span>

          <mat-menu #profilemenu class="profile-row">

            <div class="feedback-row-button">
              <button mat-raised-button (click)="onFeedbackClick()">
                <mat-icon>rate_review</mat-icon>
                <span>Feedback</span>
              </button>
            </div>

            <div class="profile-row-sep"></div>

            <div class="profile-row-button">
              <button mat-raised-button (click)="onClick()">
                <mat-icon>exit_to_app</mat-icon>
                <span>Sign out</span>
              </button>
            </div>

            <div class="profile-row-sep"></div>

            <div class="profile-row-build-details">
              UI Build Date: {{uiBuildDetails}}
            </div>
            <div class="profile-row-build-details">
              API Build Date: {{(apiSettings$ | async)}}
            </div>
          </mat-menu>
        </ng-container>
      </div>
    </div>

  </mat-sidenav>
  
  <mat-sidenav-content class="sidenav-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>