import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { IconRegistryService } from '../../services/iconregistry.service';
import { environment } from 'src/environments/environment';
import { UserFacade } from '../../facades/user.facade';
import { MsalService } from '@azure/msal-angular';
import { MenuFacade } from '../../facades/menu.facade';
import {
  MenuItem,
  MenuItemType,
} from 'src/app/shared/interfaces/menu-item.interface';
import { Router } from '@angular/router';
import {
  AppSettingsService,
  currentAppSettings,
} from 'src/app/appsettings.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FeedbackComponent } from 'src/app/core/components/feedback/feedback.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.interface';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { EllyRoutes } from 'src/app/app.routing';
import { SidenavOpenService } from '../../services/sidenav-open.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss',
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  readonly MenuItemType = MenuItemType;
  readonly router = inject(Router);
  readonly isExternal = environment.isExternal;

  readonly CLOSED_PADDING: number = -0.5;
  readonly OPEN_PADDING: number = 14;

  sideMenuOpened: boolean = false;
  sideMenuTogglePadding = this.CLOSED_PADDING;

  envName: string = environment.name;

  apiSettings$ = this.appSettingsService.apiSettings$;

  currentUser$ = this.userFacade.currentUser$;
  currentUserPic$ = this.userFacade.currentUserPic$;

  menuItems$ = this.menuFacade.menuItems$;
  ngUnsubscribe: Subject<any> = new Subject();

  uiBuildDetails: string = currentAppSettings?.uiBuildDetails;

  constructor(
    private dialog: MatDialog,
    private iconRegistryService: IconRegistryService,
    private appSettingsService: AppSettingsService,
    private userFacade: UserFacade,
    private menuFacade: MenuFacade,
    private authService: MsalService,
    private themeService: ThemeService,
    private sidenavOpenService: SidenavOpenService
  ) {}

  ngOnInit(): void {
    this.subsCurrentUser();
    this.appSettingsService.getAppDetails();
    this.updateSideMenuValues();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  subsCurrentUser() {
    this.currentUser$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User | null) => {
        if (user) {
          this.menuFacade.getMenuItems(user);
        }
      });
  }

  onClick(): void {
    const account = this.authService.instance.getActiveAccount();
    this.authService.logoutRedirect({ account: account });
  //  this.authService.logout();
  //  this.authService.logoutRedirect({postLogoutRedirectUri:'/'});
  }

  onMenuClick(menuItem: MenuItem): void {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([menuItem.navigateTo]));
  }

  onChatDashboardNavigate(): void {
    this.router.navigate([EllyRoutes.CHAT_DASHBOARD]);
  }

  onFeedbackClick() {
    this.openDialog();
  }

  openDialog() {
    const dialogRef = this.dialog.open(FeedbackComponent, {
      height: '425px',
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  eliassenLogo(): String {
    if (this.themeService.appThemeString == this.themeService.DARK_THEME ) {
      return "assets/images/Eliassen_Horizontal_White.png";
    }

    return "assets/images/eg-logo.png";
  }

  ellyLogo(): String {
    var color: string = "blue";

    if (this.themeService.appThemeString == this.themeService.DARK_THEME ) {
      color = "white";
    }

    return "assets/images/elly-logo-" + color + ".png";
  }

  toggleSideMenu() {
    this.sidenavOpenService.setSidenavIsOpen(!this.sideMenuOpened);
    this.updateSideMenuValues();
  }

  private updateSideMenuValues() { 
    this.sideMenuOpened = this.sidenavOpenService.sidenavIsOpen;
    this.sideMenuTogglePadding = this.getSidePadding();
  }

  getSidePadding(): number {
    if (this.sideMenuOpened) {
      return this.OPEN_PADDING;
    }

    return this.CLOSED_PADDING;
  }
}
