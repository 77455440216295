<div class="container">
    <div class="container-zero">

        <div class="elly-container">

            <div class="elly-title">
                Elly - AI Assistant
            </div>
            <div>
                <img class="elly-bot-image" src="assets/images/ellyBot-transparent.png" />
            </div>

        </div>
    </div>

    <div class="container-one">

        <div class="container-one1">
            <div class="message">
                You have been logged out. Please log back in to access Elly.

            </div>
            <div class="login-button-row">

                <button mat-raised-button color="login" [routerLink]="[initiateRoute()]">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Login</span>
                </button>
            </div>
        </div>


    </div>


</div>