import { ChatContent } from '../interfaces/message.interface';
import {
  AfterContentChecked,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Directive({ selector: '[citationLinkify]' })
export class CitationLinkifyDirective implements AfterContentChecked {
  @Input('citationLinkify') chatContent!: ChatContent;
  @Output() onCitationLinkClick = new EventEmitter<ChatContent>();

  constructor(private el: ElementRef) {
    (window as any).citationLinkify = this;
  }

  ngAfterContentChecked(): void {
    if (this.el.nativeElement.innerHTML.indexOf('#done') !== -1) {
      return;
    }

    if (this.chatContent && this.chatContent.citations) {
      for (const key in this.chatContent.citations) {
        if (this.el.nativeElement.innerHTML.indexOf(`#done${Object.keys(this.chatContent.citations[key])[0]}`) === -1) {
          this.el.nativeElement.innerHTML =
            this.el.nativeElement.innerHTML?.replaceAll(
              Object.keys(this.chatContent.citations[key])[0],

              `<a #done${Object.keys(this.chatContent.citations[key])[0]} class="citationLinkify highlight" onClick= window.citationLinkify.onCitationReferenceClick(${
                this.chatContent.id
              },'${Object.keys(this.chatContent.citations[key])[0]}')> ${
                Object.keys(this.chatContent.citations[key])[0]
              }
                </a>`
            );
        }
      }
    }
  }

  onCitationReferenceClick(id: number, key: string) {
    const selectedCitation: ChatContent = {
      id: id,
      message: key,
      agent: 'user',
    };

    this.onCitationLinkClick.emit(selectedCitation);
  }
}
