import {
  Component,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { User } from '../../interfaces/user.interface';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() user!: User;

  @Output()
  click = new EventEmitter<void>();

  get userNameInitials(): string {
    return (this.user.displayName ?? '').match(/(\b\S)?/g)?.join("") ?? '';
  }

  onClick() {
    this.click.emit();
  }
}
