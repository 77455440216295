import { Component } from "@angular/core";
import { EllyRoutes } from "src/app/app.routing";
import { ThemeService } from "src/app/shared/services/theme.service";

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrl: './logged-out.component.scss'
})
export class LoggedOutComponent {
  constructor (private themeService: ThemeService){

  }

  initiateRoute(): string {
    return EllyRoutes.CHAT_INITIATE;
  }
}
