import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'side-menu-toggle',
  templateUrl: './side-menu-toggle.component.html',
  styleUrl: './side-menu-toggle.component.scss'
})
export class SideMenuToggleComponent {
  @Input() isOpen!: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();

  changeIsOpen(isOpen: boolean) {
    this.isOpen = isOpen;
    this.isOpenChange.emit(this.isOpen);
  }
}
