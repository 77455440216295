import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MarkdownModule } from 'ngx-markdown';
import { MatTabsModule } from '@angular/material/tabs';
import { AvatarComponent } from './components/avatar/avatar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LinebreaksPipe } from './pipes/linebreaks.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { CitationLinkifyDirective } from './directives/citation-linkify.directive';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MermaidVisioComponent } from './components/mermaid-visio/mermaid-visio.component';
import { PrettyJsonPipe } from './pipes/prettyjson.pipe';
import { MatTableModule } from '@angular/material/table'  
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [
    AvatarComponent,
    MermaidVisioComponent,
    LinebreaksPipe,
    CitationLinkifyDirective,
    PrettyJsonPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MarkdownModule,
    MatTabsModule,
    MatSidenavModule,
    MatListModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatPaginatorModule,
    MatRadioModule,
    MatTableModule,
    MatCheckboxModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MarkdownModule,
    MatTabsModule,
    MatSidenavModule,
    MatListModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatPaginatorModule,
    MatRadioModule,
    MatTableModule,
    MatCheckboxModule,

    AvatarComponent,
    MermaidVisioComponent,
    LinebreaksPipe,
    PrettyJsonPipe,
    CitationLinkifyDirective,
  ],
  providers: [
    //ApiService
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
