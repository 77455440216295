
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { AppSettings } from './shared/interfaces/appsettings.interface';
import { BehaviorSubject, Observable } from 'rxjs';


export let currentAppSettings: AppSettings;

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {

    protected apiSettings: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    public apiSettings$: Observable<string | null> = this.apiSettings.asObservable();

    private headers = new HttpHeaders(
        {
            //'Content-Type': 'application/json',
            //'Authorization': `Bearer ${this.apiKey}`
            'Access-Control-Allow-Methods': 'GET,POST'
            , 'Access-Control-Allow-Origin': '*'//'https://api.openai.com'
            , 'Access-Control-Allow-Headers': '*'//'Origin, X-Requested-With, Content-Type, Accept'
        });


    constructor(private httpClient: HttpClient) {


    }

    loadAppSettings() {
        this.httpClient.get('/assets/config/appsettings.json').toPromise().then(
            (data: any) => {
                currentAppSettings = data;
            }
        )
    }



    public getAppDetails() {

        this.apiSettings.next(null);

        return this.httpClient.get<string>(`${currentAppSettings.apiUrl}` + 'App/Build').subscribe(
            (response: string) => {

                this.apiSettings.next(response);

            }
        );
    }

}
