import { ModuleWithProviders, NgModule } from '@angular/core';
import { UserProfileImageComponent } from './components/user-profile-image/user-profile-image.component';
import { LoggedOutComponent } from './components/logged-out/logged-out.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { ThemeToggleComponent } from '../shared/components/theme-toggle/theme-toggle.component';
import { ThemeService } from '../shared/services/theme.service';
import { UserService } from './services/user.service';
import { UserFacade } from './facades/user.facade';
import { MenuFacade } from './facades/menu.facade';
import { MenuService } from './services/menu.service';
import { MatDialogModule } from '@angular/material/dialog';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FeedbackService } from './services/feedback.service';
import { FeedbackFacade } from './facades/feedback.facade';
import { SideMenuToggleComponent } from './components/side-menu-toggle/side-menu-toggle.component';
import { SidenavOpenService } from './services/sidenav-open.service';
import { DateService } from './services/date.service';

@NgModule({
  declarations: [
    AppLayoutComponent,
    LoggedOutComponent,
    UserProfileImageComponent,
    FeedbackComponent,
    ThemeToggleComponent,
    SideMenuToggleComponent
  ],
  imports: [
    MatIconModule,
    RouterModule,
    CommonModule,

    MatToolbarModule,
    MatMenuModule,
    SharedModule,
    MatDialogModule

  ],
  exports: [
    UserProfileImageComponent,
  ],
  providers: [
    //ApiService

    MenuFacade,
    MenuService,
    FeedbackService,
    FeedbackFacade
  ]
})
export class CoreModule {

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        UserService,
        UserFacade,
        FeedbackFacade,
        FeedbackService,
        ThemeService,
        SidenavOpenService,
        DateService
      ]
    }
  }
}
