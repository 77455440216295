export enum MenuItemType {
  DEFAULT,
  CUSTOM,
}

export interface MenuItem {
  id: number;
  name: string;
  navigateTo: string;
  iconName: string;
  menuType: MenuItemType;
  tooltip?: string;
  children?: MenuItem[];
}
