import { Injectable } from "@angular/core";
import { MenuService } from "../services/menu.service";
import { User } from "src/app/shared/interfaces/user.interface";

@Injectable()
export class MenuFacade {

    menuItems$ = this.menuService.menuItems$;

    constructor(private menuService: MenuService) {

    }

    public getMenuItems(user: User | null) {

        this.menuService.getMenuItems(user);

    }

}
