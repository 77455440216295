import { Injectable } from "@angular/core";
import { FeedbackService } from "../services/feedback.service";
import { FeedbackRequest } from "src/app/shared/interfaces/feedback.interface";

@Injectable()
export class FeedbackFacade {

    constructor(private feedbackService: FeedbackService) {

    }

    public submitFeedback(feedbackRequest: FeedbackRequest) {

        this.feedbackService.submitFeedback(feedbackRequest);

    }

}
