// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '1.0.0',
  msalConfig: {
      auth: {
          clientId: 'fb19e95b-a9ce-4972-bdd6-924a26e53128',
          authority: 'https://login.microsoftonline.com/67a80b53-1b4f-4278-b269-182b0c395974'
      }
  },
  apiConfig: {
    scopes: ['api://2ddd027e-2b60-411f-8271-a6f9c666110c/user_impersonation'],
    uri: 'https://app-elly-eg-dev-api.azurewebsites.net/api/',
    uriLocal: 'https://localhost:7063/api/'
  },
  name: 'DEV',
  isExternal: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI. Test
