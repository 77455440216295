<button mat-fab color="primary" class="avatar__button" (click)="onClick()">
    <ng-container *ngTemplateOutlet="buttonTmpl"></ng-container>
</button>

<ng-template #buttonTmpl>
    <div class="avatar">
        <img class="avatar__image" *ngIf="user.image; else letterTmpl" [src]="user.image" />
    </div>
</ng-template>

<ng-template #letterTmpl>
    <span class="avatar__letter">{{ userNameInitials }}</span>
</ng-template>
