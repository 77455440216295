import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import mermaid from 'mermaid';

@Component({
  selector: 'app-mermaid-visio',
  templateUrl: './mermaid-visio.component.html',
  styleUrls: ['./mermaid-visio.component.scss'],
})
export class MermaidVisioComponent {
  constructor(private sanitizer: DomSanitizer) {}

  graphDefinition: string = '';
  svg: any;

  @ViewChild('mermaidDiv', { read: ElementRef }) mermaidDiv!: ElementRef;
  @Input() set data(value: string) {
    if (!value) return;

    this.graphDefinition =  value;
    this.renderVisio();
  }

  private async renderVisio() {
    const { svg, bindFunctions } = await mermaid.render(
      'graphDiv' + this.guidGenerator(),
      this.graphDefinition
    ); // => {
    //this.mermaidDiv.nativeElement.innerHTML = svg; // this.graphDefinition;
    this.svg = this.sanitizer.bypassSecurityTrustHtml(svg);
  }

  private guidGenerator(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r: number = (Math.random() * 16) | 0;
      const v: number = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
