import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class ThemeService {
    readonly THEME_SAVE_NAME: string = 'app-theme';
    readonly LIGHT_THEME: string = "LIGHT";
    readonly DARK_THEME: string = "DARK";
    readonly DEFAULT_THEME: string = this.LIGHT_THEME;

    appThemeString: string | null = this.DEFAULT_THEME;

    constructor(@Inject(DOCUMENT) private document: Document) { 
        
        if (localStorage.getItem(this.THEME_SAVE_NAME)) {
            this.appThemeString = localStorage.getItem(this.THEME_SAVE_NAME);
        }
        else { 
            this.appThemeString = this.DEFAULT_THEME;
        }

        this.setAppTheme(this.getThemeString(this.appThemeString));
    }

    setAppTheme(newTheme: string) {
        let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

        this.appThemeString = newTheme;

        themeLink.href = this.getAppTheme() + '.css';
        localStorage.setItem(this.THEME_SAVE_NAME, newTheme);
    }

    getAppTheme(): string {
        return this.getThemeClassString(this.appThemeString);
    }

    getThemeString(theme: string | null): string {
        return theme? theme : this.DEFAULT_THEME; 
    }

    getThemeClassString(theme: string | null): string {
        let themeString: string = theme == this.DARK_THEME ? 'theme-dark-mode' : 'theme-light-mode';
        return themeString;
    }
}