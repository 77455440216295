import { Injectable } from '@angular/core';
import { IconResolver, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  iconNames: string[] = [
    'robot_2',
    'cloud_upload',
    'arrow_upward',
    'quick_phrases',
    'speech_to_text',
    'record_voice',
    'voice_over',
    'campaign_black',
    'content_copy',
    'replay',
    'done',
    'arrow_circle_down',
    'network_node',
    'account_tree',
    //'gavel',
    'image',
    'mop',
    'construction',
    'file_type_word',
    'file_type_powerpoint',
    'file_type_pdf2',
    'file_type_txt',
    'stop_circle',
    'history',
    'military_tech',
    'psychology',
    'summarize',
    'text_to_speech',
    'graphic_eq',
    'stop',
    'mic',
    'hearing',
    'claude-ai-icon',
    'quiz',
    'settings',
    'support_agent',
    'account_balance',
    'savings',
    'culvers_logo',
    'doc_file management'
  ];

  constructor(private iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    this.iconNames.forEach((name) => {
      const resolver: IconResolver = (name) =>
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${name}.svg`);

      iconRegistry.addSvgIconResolver(resolver);
    });
  }

  public iconExistsInRegistry(iconName: string): boolean {
    return this.iconNames.some(
      (icn) => icn.toLowerCase() === iconName.toLowerCase()
    );
  }

  public getFileAttachmentIcon(fileType: string): string {
    let iconName: string = 'file_type_word';

    switch (fileType) {
      case 'application/pdf':
        iconName = 'file_type_pdf2';
        break;

      case 'text/plain':
        iconName = 'file_type_txt';
        break;
    }

    return iconName;
  }
}
