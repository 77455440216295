import { Injectable } from "@angular/core";
import { UserService } from "../services/user.service";

@Injectable()
export class UserFacade {

    currentUser$ = this.userService.currentUser$;
    currentUserPic$ = this.userService.currentUserPic$;

    constructor(private userService: UserService) {

    }

    public getCurrentUserProfile() {

        this.userService.getCurrentUserProfile();

    }

    public getCurrentUserPic(id: string) {

        this.userService.getCurrentUserPic(id);

    }

}
