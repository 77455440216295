<div class="title-container">
    <p class="m-1">Give Feedback</p>

</div>

<form [formGroup]="feedbackForm" class="o-h">

    <div class="m-1 f-w-500">
        What's on your mind?
    </div>

    <div class="feedback container-one m-1/2">
        <mat-form-field>
            <mat-label>Feedback</mat-label>
            <textarea #txtDescription matInput formControlName="comments" rows="8" class="no-resize"></textarea>
        </mat-form-field>

        <!-- <mat-progress-bar mode="indeterminate"
        *ngIf="ellyResponseIsLoading$ | async"></mat-progress-bar> -->

    </div>

    <div class="m-1/2 disp-f jc-fe">

        <span class="container-four-child">
            <button mat-raised-button (click)="clearForm()">Cancel</button>
        </span>

        <span class="container-four-child">
            <button mat-raised-button color="primary" [disabled]="!(feedbackForm.get('comments')?.value?.length >= 2)"
                (click)="submitFeedback()">Submit</button>
        </span>
    </div>



</form>